.no-bullets {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.listContainer {
  font-family: 'republika' !important;
  color: white !important;
  background-image: linear-gradient(
    to bottom right,
    rgba(255, 255, 255, 0.2),
    rgba(255, 255, 255, 0.1)
  );
  backdrop-filter: 'blur(10px)';
}

.listFont {
  color: white !important;
  font-size: larger !important;
  font-family: 'montserrat-light' !important;
}

.listFont > a {
  color: white !important;
  font-family: 'montserrat-light' !important;
  text-decoration: none !important;
}

.listFont > a:hover {
  color: #97b690 !important;
}

.listFont > TableCell {
  color: white !important;
  font-family: 'montserrat-light' !important;
}

.listHeaderFont {
  font-family: 'republika' !important;
  font-size: calc(1rem + 0.5vw);
}
