.standard {
  text-align: center;
  align-items: center;
  align-self: center;
  justify-content: center;
  color: aliceblue;
}

.standard-box {
  text-align: center;
  align-items: center;
  align-self: center;
  justify-content: center;
  color: aliceblue;
  display: 'flex';
  height: '30vh';
  background-image: linear-gradient(
    to bottom right,
    rgba(255, 255, 255, 0.2),
    rgba(255, 255, 255, 0.1)
  );
  backdrop-filter: 'blur(10px)';
  margin-top: '5vh';
  margin-bottom: '5vh';
  margin-left: '15vw';
  margin-right: '15vw';
}

.standard-inner-window {
  text-align: center;
  align-items: center;
  align-self: center;
  justify-content: center;
  color: aliceblue;
  background-image: linear-gradient(
    to bottom right,
    rgba(30, 13, 46, 0.829),
    rgba(38, 11, 46, 0.582)
  );
  backdrop-filter: 'blur(10px)';
  margin-top: 0.5vh;
  padding-bottom: 5vh;
  padding-top: 5vh;
  border: 0.3vh solid #97b690;
}

.standard-outer-window {
  text-align: center;
  align-items: center;
  align-self: center;
  justify-content: center;
  color: aliceblue;
  background-color: #97b69055;
  backdrop-filter: 'blur(10px)';
  padding-top: 1vh;
  padding-bottom: 1.5vh;
  padding-left: 1vw;
  padding-right: 1vw;
  border: 0.3vh solid #97b690;
}

.header-image {
  max-height: 100px;
  width: 100%;
}

.standard-stack > h1 {
  font-size: calc(1rem + 1vw) !important;
  font-family: 'republika' !important;
}

.standard-stack > p {
  font-size: calc(1rem + 0.5vw) !important;
  font-family: 'montserrat-light' !important;
  overflow-wrap: break-word;
}

a.inTextLink {
  color: white !important;
}

.left {
  justify-content: flex-start;
}

.right {
  justify-content: flex-end;
}

.flex {
  justify-content: flex-end;
}
