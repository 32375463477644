.spotify-button {
  background-color: #97b690 !important;
  color: white !important;
  border-radius: 0px !important;
  padding-left: 2;
  padding-right: 2;
  padding-top: 1;
  padding-bottom: 1;
  width: 20%;
  font-family: 'montserrat-semibold' !important;
}

.spotify-button:hover {
  color: black !important;
  background-color: #ffffff !important;
}

.basic-button {
  color: white !important;
  background-color: #97b690 !important;
  border-radius: 0px !important;
  font-family: 'montserrat-semibold' !important;
}

.basic-button:hover {
  color: black !important;
  background-color: #ffffff !important;
}
.sticky-header {
  padding-top: 6vh;
  padding-bottom: 1vh;
  top: 0;
  z-index: 999;
  background: rgb(15, 8, 26) !important;
}
.basic-field {
  background-color: #97b69065;
}

.MuiFormLabel-root.Mui-focused {
  color: white !important;
}
.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: #ffffff00 !important;
  border-radius: 0px !important;
}
.MuiInputLabel-root {
  color: white !important;
  font-family: 'montserrat-medium' !important;
}
#radar {
  background-color: rgba(255, 255, 255, 0);
}
#venn {
  display: flex;
  align-items: center;
  justify-content: center;
}
