body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: rgb(15, 8, 26) !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'republika';
  src: url(./fonts/R4EXPUI_.ttf) format('truetype');
}

@font-face {
  font-family: 'montserrat-extralight';
  src: url(./fonts/Montserrat-ExtraLight.ttf) format('truetype');
}

@font-face {
  font-family: 'montserrat-light';
  src: url(./fonts/Montserrat-Light.ttf) format('truetype');
}

@font-face {
  font-family: 'montserrat-medium';
  src: url(./fonts/Montserrat-Medium.ttf) format('truetype');
}

@font-face {
  font-family: 'montserrat-regular';
  src: url(./fonts/Montserrat-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'montserrat-semibold';
  src: url(./fonts/Montserrat-SemiBold.ttf) format('truetype');
}

/*
.color-picker > fieldset {
  border: 0;
  display: flex;
  gap: 2rem;
  width: 100%;
  background-color: #fff;
  padding: 1rem 3rem;
  margin-inline: auto;
  border-radius: 0 0 0 0;
  bottom: 0;
  position: fixed;
}

.color-picker input[type='radio'] {
  appearance: none;
  width: 1.5rem;
  height: 1.5rem;
  border: 3px solid var(--radio-color, currentColor);
  outline-offset: 3px;
  border-radius: 50%;
}

.color-picker input[type='radio']:checked {
  background-color: var(--radio-color);
}

.color-picker input[type='radio']#blue {
  --radio-color: blue;
}

.color-picker input[type='radio']#green {
  --radio-color: green;
}

.color-picker input[type='radio']#black {
  --radio-color: black;
}*/

:root {
  --lightgreen: #97b690;
  --brightpink: #ff00ff;
  --brightcyan: #00ffff;
  --black: #000000;
}

/*
:root:has(#blue:checked) {
  --c1: hsl(219, 79%, 66%);
  --c2: hsl(319, 100%, 60%);
  --c3: hsl(266, 49%, 25%);
  --c4: hsl(60, 82%, 58%);
  --c5: hsl(0, 67%, 55%);
  --c6: hsl(213, 96%, 11%);
  --c2hsl: 319, 100%, 60%;
}

:root:has(#green:checked) {
  --c1: hsl(9, 79%, 66%);
  --c2: hsl(9, 100%, 60%);
  --c3: hsl(6, 49%, 25%);
  --c4: hsl(0, 82%, 58%);
  --c5: hsl(0, 67%, 55%);
  --c6: hsl(3, 96%, 11%);
  --c2hsl: 9, 100%, 60%;
}

:root:has(#black:checked) {
  --c1: hsl(0, 79%, 6%);
  --c2: hsl(9, 100%, 0%);
  --c3: hsl(6, 49%, 5%);
  --c4: hsl(0, 82%, 8%);
  --c5: hsl(0, 67%, 5%);
  --c6: hsl(3, 96%, 1%);
  --c2hsl: 319, 0%, 60%;
}
*/

.header-image:has(#blue:checked) {
  display: block;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  background: url('./assets/bluelogo.png') no-repeat;
  width: 1080px; /* Width of new image */
  height: 2306px; /* Height of new image */
  padding-left: 1800px; /* Equal to width of new image */
}
